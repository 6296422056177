import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Carousel, SectionMenu } from '@dstv-web-leanback/dstv-frontend-components'
import { GET_CHANNELS } from '@dstv-web-leanback/dstv-frontend-services'

import { useFocusable, FocusContext, setFocus } from '@connected-video-web/norigin-spatial-navigation'

import styles from './Iplate.module.scss'

export function Iplate({ active, switchChannel }) {
	const { focusKey, ref } = useFocusable({ focusKey: 'IPLATE', focusable: active })

	const sectionsState = useSelector((state) => state.sections)
	const channelsState = useSelector((state) => state.channels)
	const dispatch = useDispatch()

	useEffect(() => {
		if (active) {
			setFocus('SECTION_MENU')
		}
	}, [active])

	useEffect(() => {
		let activeSectionUrl = sectionsState?.data?.[sectionsState.activeSection]?.url

		if (activeSectionUrl) {
			dispatch(GET_CHANNELS({ url: activeSectionUrl }))
		}
	}, [sectionsState.activeSection])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.iplate} ${active && styles.expanded}`}>
				{active && (
					<div className={styles.iplate_menu_wrapper}>
						<SectionMenu path={'/livetv/'} gridFocused={false} focusKey={'SECTION_MENU'} />
					</div>
				)}
				<Carousel cards={channelsState?.channels} type="iplate" active={active} switchChannel={switchChannel} />
			</div>
		</FocusContext.Provider>
	)
}
export default Iplate
